.index_root {
    /* transform: scale(1, 0.85); */
}

.main_root {
    /* transform: scale(0.85, 1); */
}

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}


.login_root_box {
    width: 100%;
    height: 100%;
    /* background-image: url(../img/login_bg.jpg); */
    background-size: 100% 100%;
}


.login_root_box_in {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_root {
    width: 440px;
    background-color: rgba(255, 255, 255, 0.8);
    padding-bottom: 20px;
    padding-top: 10px;
    border-radius: 10px;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 150px;
}

.login-form-button {
    width: 100%;
}

.router {
    float: right;
    width: calc(100% - 250px);
}

.blue {
    color: rgb(64, 169, 255);
}

.download-table {
    /* width: 125px; */
    /* height: 32px; */
    color: #1890ff;
    font-weight: bold;
    background-color: #ffffff;
    border-color: #1890ff;
    /* text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12); */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.main_title {
    color: #696a6b;
    text-align: center;
    padding: 5px 0;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: bold;
}

/* @media screen and (max-width: 550px) {
    .main_title {
        display: none;
    }
} */

.select_before {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-size: 14px;
    box-sizing: border-box;
    height: 32px;
    line-height: 30px;
    text-indent: 10px;
    border-right: none;
    display: inline-block;
    width: 152px;
}

.down_btn {
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-size: 14px;
    box-sizing: border-box;
    height: 32px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    padding: 0 10px;
}

.explain_div {
    line-height: 25px;
    border-bottom: 2px solid #f5f5f5;
    padding: 3px 0;
}

.nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.span_head {
    font-weight: bold;
    color: #5f5f5f;
}

.ant-menu-dark .ant-menu-item a {
    color: #ffffff !important;
}

.ant-menu-ligh .ant-menu-title-content {
    color: #000000 !important;
}

.ant-menu-light .ant-menu-item:hover {
    color: #8c1c21 !important;
}

.ant-menu-light .ant-menu-submenu-title:hover {
    color: #8c1c21 !important;
}

.ant-menu-light .ant-menu-submenu-title:hover i {
    color: #8c1c21 !important;
}

.ant-menu-light .ant-menu-item-selected {
    color: #8c1c21;
    font-weight: bold;
}

.ant-menu-light .ant-menu-submenu-selected {
    color: #8c1c21;
}

.ant-menu-light .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffdede;
}

.ant-menu-light .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #8c1c21;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #8c1c21 !important;
}

.ant-tabs-ink-bar {
    background-color: #8c1c21 !important;
}

.ant-tabs-tab-active {
    /* border-bottom: 2px solid #8c1c21 !important;
    border: none!important; */
}

.ant-tabs-tab:hover {
    color: #8c1c21 !important;
}

.item_left_border {
    border-left: 3px solid #1890ff;
    font-weight: bold;
    border-radius: 2px;
    line-height: 17px;
    font-size: 15px;
    text-indent: 4px;
}

.item_left_value {
    font-size: 14px;
    text-indent: 7px;
    margin-top: 4px;
    line-height: 16px;
}

.detail_top_nav {
    height: 45px;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 1000;
    border-bottom: 2px solid #c6c6c6;
    overflow: hidden;
}

.samlp_item {
    box-shadow: 1px 2px 2px 2px rgb(197, 197, 197);
    text-align: center;
    cursor: pointer;
    color: #0e0e0e;
    line-height: 26px;
    width: 110px;
    float: left;
}

.samlp_item_focus {
    box-shadow: 1px 2px 2px 2px #bedffe;
    background-color: #2db7f5;
    color: #ffffff;
    font-weight: bold;
}

.samlp_item_left {
    border-bottom: 1px solid #cfcdcd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.ques_icon {
    margin-left: 3px;
    font-size: 12px;
    cursor: pointer;
    color: #8a8989;
}

.img_camera_box image {
    width: 400px !important;
    height: 300px !important;
}



.amap-copyright {
    opacity: 0 !important;
}

.amap-logo {
    width: 0 !important;
    opacity: 0 !important;
}

#container {
    background-color: #092f66 !important;
    background-image: none;
}

.index_each_box {
    background-color: #092f66;
}

.index_each_box_title {
    background-color: #051d3f;
    color: #28a3ef;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    position: relative;
}

.index_each_box_title a {
    color: #28a3ef;
    position: absolute;
    right: 10px;
}

.each_bg {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    padding: 10px;
}

.each_bg2 {}

.each_bg3 {}

.each_bg4 {}

.index_city {
    display: inline-block;
    width: 215px;
    text-align: right;
    margin-right: 7px;
}

.chart_style {
    width: 30%;
    margin: 1%;
    float: left;
    height: 300px;
}

.son_table {
    margin: 15px 0;
    /* border: 2px solid #b4b4b4; */
    padding: 20px 20px 20px 0px;
    box-shadow: 2px 2px 3px 3px rgb(197, 197, 197);
}

.son_table tr {
    background-color: #f5f5f5 !important;
}

.son_table th {
    background-color: #f5f5f5 !important;
}




.test {
    /* #edfbff */
    color: #e4e4e4;
    color: #888787;
    color: #4d4d4d;
    color: #0068c9;
    color: #1890ff;
    color: #20a2ff;
    color: #00daea;
    color: #20b759;
    color: #825de0;
    color: #f300d3;
}

.table_preview {
    width: 100%;
}

.table_preview .table_preview th,
.table_preview td {
    border: 1px solid #858585;
    border-collapse: collapse;
    /* 移除单元格之间的间隔 */
}

.table_preview td {
    padding: 3px;
}

.priview_div img {
    display: block;
    margin: 5px auto;
    width: 500px !important;
}

.reg_box {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding-bottom: 20px;
}

.reg_box .login_ipt {
    margin-top: 15px;
}

.reg_pre_mention {
    width: 110px;
    text-align: left;
    text-indent: 0px;
}

.week_set_tr {}

.week_set_tr .each_td {
    width: 14.2%;
    display: inline-block;
    margin-top: 30px;
}

.calendar_box {
    height: 240px;
    overflow-y: auto;
    width: 94%;
    margin-left: 3%;
    border-top: 2px solid #b0afaf;
    padding: 10px 0;
    color: #414141;
    position: relative;
}

.calendar_box_icon {
    position: absolute;
    bottom: 2px;
    right: 2px;
}

.calendar_box::-webkit-scrollbar {
    width: 0;
}

.calendar_box_has_data:hover {
    background-color: #8c1c21;
}

.calendar_box_has_data:hover div {
    color: #ffffff !important;
}

.calendar_box_has_data:hover .calendar_box_icon {
    color: #ffffff !important;
}

.calendar_box_in {
    width: 92%;
    margin-left: 4%;
}

.each_course_div {
    margin-bottom: 15px;
    cursor: pointer;
}

.calendar_switch {
    color: #8c1c21 !important;
    font-size: 28px;
    margin-left: 10px;
    cursor: pointer;
}

.banner_bottom_nav a {
    margin-right: 20px;
    color: #ffffff;
}

.banner_bottom_nav a:hover {
    color: #ffffff;
}

.activity_list_box {
    overflow: hidden;
    width: 20%;
    display: inline-block;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 3px 3px 3px rgba(76, 76, 76, 0.2);
}

.activity_list_item {
    color: #959595;
    font-size: 13px;
    margin-top: 5px;
}

.default_btn {
    color: #8c1c21;
    border: 1px solid #8c1c21;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
}

.default_btn:hover {
    background-color: #8c1c21;
    color: #fff;
}

.primary_btn {
    border: 1px solid #8c1c21;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    background-color: #8c1c21;
    color: #fff;
}

.ant-radio-inner::after {
    background-color: #8c1c21 !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #8c1c21 !important;
}

.main_title_red {
    color: #8c1c21;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
}

.course .ant-table-thead>tr>th {
    background-color: #747b83 !important;
    color: #ffffff !important;
}

.path_content_a {
    width: 75%;
    display: inline-block;
    background-color: #f5eff1;
    color: #3b3b3b;
    padding: 10px 0;
    text-align: center;
    border-radius: 0 16px 0 16px;
    font-weight: bold;
}

.path_content_a:hover {
    color: #626262;
}

.path_content_index {
    text-align: left;
    line-height: 30px;
    width: 30px;
    height: 30px;
    font-size: 25px;
    background-image: url(../img/orange_point.jpg);
    background-size: 30px 30px;
    background-position: 0 0;
}

.characteristic_icon {
    background-color: #333333;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border-radius: 40px;
    overflow: hidden;
    margin: 0 auto;
}


.characteristic_icon_img_1 {
    display: inline-block;
}

.characteristic_icon_img_2 {
    display: none;
}


.characteristic_icon_box {
    background-color: #f0f0f0;
}

.characteristic_icon_box:hover {
    background-color: #8c1c21;
}

.characteristic_icon_box:hover div {
    color: #ffffff !important;
}

.characteristic_icon_box:hover .characteristic_icon {
    background-color: #ffffff;
}

.characteristic_icon_box:hover .characteristic_icon_img_1 {
    display: none;
}

.characteristic_icon_box:hover .characteristic_icon_img_2 {
    display: inline-block;
}

.underline {
    width: 20px;
    height: 3px;
    background-color: #8c1c21;
}

.recent_news {
    display: block;
    width: 30%;
    margin-left: 1.6%;
    margin-right: 1.6%;
    float: left;
    box-sizing: border-box;
    padding-bottom: 20px;
    border: 1px solid #ffffff;
}

.recent_news div {
    color: #323131;
}

.recent_news img {
    width: 100%;
}

.recent_news:hover {
    border: 1px solid #8c1c21;
}

.recent_news:hover .recent_news_title {
    color: #8c1c21;
}

.recent_news_title a {
    color: #323131;
}

.recent_news_title:hover a {
    color: #323131;
}

.new_class_icon_box {
    width: 25%;
    float: left;
    background-color: #f7f7f7;
    height: 330px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.new_class_icon_box2 {
    flex-direction: row;
    width: 100%;
    height: 160px;
}

.new_class_icon_box img {
    width: 60px;
    height: 60px;
}

.new_class_icon_box .new_class_icon_box_img_1 {
    display: inline-block;
}

.new_class_icon_box .new_class_icon_box_img_2 {
    display: none;
}

.new_class_icon_box:hover {
    background-color: #8c1c21;
}

.new_class_icon_box:hover div {
    color: #ffffff;
}

.new_class_icon_box:hover .new_class_icon_box_img_1 {
    display: none;
}

.new_class_icon_box:hover .new_class_icon_box_img_2 {
    display: inline-block;
}

.new_class_icon_box_title {
    color: #8c1c21;
    font-weight: bold;
    margin-top: 20px;
    font-size: 20px;
    margin-bottom: 10px;
}

.new_class_icon_box_arrow {
    font-weight: bold;
    margin-top: 20px;
}

.course_core_new_class_box {
    text-align: center;
    box-sizing: border-box;
    border: 1px solid #f7f7f7;
    padding: 30px 0;
}

.course_core_new_class_box div {
    color: #000000 !important;
}

.course_core_new_class_box:hover {
    border: 1px solid #8c1c21;
}

.course_core_new_class_box img {
    width: 150px;
    height: 150px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.gold_seed_icon {
    box-sizing: border-box;
    width: 33%;
    padding: 6%;
    border-right: 1px solid #cbcbcb;
    text-align: center;
    float: left;
}

.gold_seed_icon_title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.gold_seed_icon img {
    width: 70%;
}

.gold_seed_icon_img_1 {
    display: inline-block;
}

.gold_seed_icon_img_2 {
    display: none;
}

.gold_seed_icon:hover .gold_seed_icon_img_1 {
    display: none;
}

.gold_seed_icon:hover .gold_seed_icon_img_2 {
    display: inline-block;
}

.gold_seed_icon:hover .gold_seed_icon_title {
    color: #8c1c21;
}

.entrepreneurship_main_title {
    font-size: 25px;
    text-align: center;
    font-weight: bold;
}

.pro_join_require {
    width: 49%;
    float: left;
    box-sizing: border-box;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 20px 2% 30px;
    margin-bottom: 20px;
}

.pro_join_require_title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.pro_join_require_img_1 {
    display: inline-block;
}

.pro_join_require_img_2 {
    display: none;
}

.pro_join_require:hover {
    background-color: #8c1c21;
}

.pro_join_require:hover div {
    color: #ffffff;
}

.pro_join_require:hover a {
    color: #ffffff !important;
}

.pro_join_require:hover .pro_join_require_img_1 {
    display: none;
}

.pro_join_require:hover .pro_join_require_img_2 {
    display: inline-block;
}

.red_shadow_box {
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 1px rgb(254, 225, 225);
    padding: 50px 30px;
}

.pro_support_item {
    box-sizing: border-box;
    width: 49%;
    float: left;
    background-color: #fdf5f5;
    border-radius: 10px;
    padding: 20px 20px;
    margin-bottom: 10px;
    color: #8c1c21;
    position: relative;
}

.pro_support_item img {
    width: 20px;
}

.pro_support_item_img_2 {
    display: none;
}

.pro_support_item:hover {
    background-color: #8c1c21;
}

.pro_support_item:hover div {
    color: #ffffff;
}

.pro_support_item:hover .pro_support_item_img_1 {
    display: none;
}

.pro_support_item:hover .pro_support_item_img_2 {
    display: inline-block;
}

.pro_support_item_index {
    position: absolute;
    left: 7px;
    top: 0;
    height: 100%;
    align-items: center;
    display: flex;
    color: #fad2d1;
    font-size: 90px;
}

.pro_support_item:hover .pro_support_item_index {
    display: none;
}

.entrepreneurship_bot_contact {
    width: 48%;
}

.practice_mian_title {
    color: #8c1c21;
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d1c7ba;
    display: inline-block;
}

.practice_ins_box {
    width: 100%;
    overflow: visible;
    box-sizing: border-box;
    padding: 15px;
    background-color: #f8f2f2;
    margin-top: 30px;
    z-index: 10;
    position: relative;
    text-align: justify;
}

.practice_ins_square {
    width: 70px;
    height: 70px;
    background-color: #8c1c21;
    z-index: 0;
    position: absolute;
    top: -10px;
    left: -10px;
}

.practice_ins_square2 {
    width: 70px;
    height: 70px;
    background-color: #8c1c21;
    z-index: 0;
    position: absolute;
    bottom: -10px;
    right: -10px;
}

.practice_characteristic_item {
    position: relative;
    margin-top: 30px;
    padding: 30px 0;
    text-align: justify;
}

.practice_characteristic_index {
    position: absolute;
    top: -10px;
    left: 20px;
    color: #8c1c21;
    width: 9%;
    text-align: center;
    height: 120px;
    line-height: 130px;
    font-size: 70px;
    font-weight: bold;
}

.practice_characteristic_item:hover {
    background-color: #f8f2f2;
}

.practice_characteristic_item:hover .practice_characteristic_index {
    background-color: #8c1c21;
    color: #ffffff;
}

.practice_course_list {
    background-color: #8c1c21;
    color: #ffffff;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
}

.practice_course_list a {
    color: #ffffff;
}

.practice_course_list a:hover {
    color: #ffffff;
}

.practice_course_list_each {
    border-top: 1px solid #ffffff;
    padding-top: 15px;
    margin-top: 15px;
}

.practice_course_sign_box {
    background-color: #f5f5f5;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
}

.teacher_team_photo_box {
    width: 24.5%;
    margin-right: 0.5%;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
}

/* 教育项目-手风琴特效中的师资 */
.edu_project .teacher_team_photo_box {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 0px;
    display: inline-block;
    position: relative;
}

.teacher_team_photo_box_desc {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    background-color: rgba(140, 28, 33, 1);
    color: #ffffff;
    box-sizing: border-box;
    padding: 6px 10px;
    /* display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0%;
    height: 0;
    overflow: hidden; */
}

.teacher_team_photo_box_desc_accordion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(140, 28, 33, 0.9);
    color: #ffffff;
    box-sizing: border-box;
    padding: 6px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0%;
    height: 0;
    overflow: hidden;
}

.teacher_team_photo_box:hover .teacher_team_photo_box_desc_accordion {
    padding: 10%;
    height: 100%;
}

.teacher_team_photo_box_in_school {
    width: 33%;
    margin-right: 0.3%;
    margin-bottom: 10px;
    display: inline-block;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    color: #8c1c21;
    padding: 3%;
    background-color: #ffffff;
    text-align: center;
}

.teacher_team_photo_box_in_school:hover {
    color: #ffffff;
    background-image: url(../img/teacher_bg.png);
}

.core_course_nav {
    display: inline-block;
    width: 50%;
    text-align: center;
    color: #000000;
    border: 1px solid #8c1c21;
    padding: 12px 0;
    font-size: 16px;
    font-weight: bold;
}

.core_course_nav:hover {
    color: #000000;
}

.core_course_nav_focuse {
    color: #ffffff;
    background-color: #8c1c21;
    border: 1px solid #8c1c21;
}

.core_course_nav_focuse:hover {
    color: #ffffff;
}


.recruit_process img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.recruit_process {
    background-color: #f9f9f9;
    padding: 20px 20px;
    box-sizing: border-box;
    border-left: 6px solid #8c1c21;
    margin-top: 10px;
    font-size: 16px;
}

.recruit_process_img2 {
    display: none;
}

.recruit_process_student:hover .recruit_process_img1 {
    display: none;
}

.recruit_process_student:hover .recruit_process_img2 {
    display: inline-block;
}

.recruit_process_student:hover {
    background-color: #8c1c21;
}

.recruit_process_student:hover div,
.recruit_process_student:hover span {
    color: #ffffff !important;
}

.entrepreneurship_incubation_ins {
    margin-top: 30px;
    font-size: 15px;
}

.incubation_icon_content {
    opacity: 0;
    position: absolute;
    bottom: 30px;
    left: 0;
    display: block;
    width: 100%;
    text-align: center;
}

.incubation_icon:hover .incubation_icon_content {
    opacity: 1;
}

/* 收缩面板 */
.ant-collapse-header {
    display: flex;
    align-items: center;
}

.collapse_panel_child {
    font-size: 16px;
    color: #8c1c21;
    font-weight: bold;
}

.collapse_father svg {
    color: #8c1c21;
}

.project_characteristic_box a {
    color: #8c1c21;
}



.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    /* color: #8c1c21 !important; */
}

.common_left_line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50px;
    border-right: 1px solid #e7e7e7;
    text-align: center;
    box-sizing: border-box;
    padding-top: 40px;
    font-size: 25px;
    background-color: #ffffff;
    line-height: 40px;
    z-index: 100;
}

@media screen and (max-width: 1520px) {
    .common_left_line {
        display: none;
    }
}


/* 创客空间-手风琴特效 */
.space .accordion {
    width: 100%;
    /* background-color: #f5f5f5; */
}

.space .accordion ul {
    width: 100%;
    height: 320px;
    overflow: hidden;
    transition: all .3s;
    padding: 0;
}

.space .accordion ul li {
    /* 相对定位 */
    position: relative;
    float: left;
    list-style-type: none;
    width: 24%;
    /* margin-right: 2%; */
    height: 320px;
    /* 过渡为0.5秒 */
    transition: all .5s;
    background-image: url(../img/advantage_small.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.space .accordion ul:hover li {
    width: 18.2%;
}

.space .accordion ul li:hover {
    width: 40%;
    background-image: url(../img/advantage_large.png);
}

.space .accordion ul li .div_box {
    /* 绝对定位 */
    position: absolute;
    left: 5%;
    top: 30px;
    width: 90%;
    color: #fff;
    transition: all .3s;
    overflow: hidden;
}

.space .accordion ul li .div_2 {
    display: none;
}

.space .accordion ul li:hover .div_2 {
    display: block;
}

.space .accordion ul li:hover .div_1 {
    display: none;
}

.space .accordion .line {
    background-color: #fff;
    width: 50px;
    height: 3px;
    margin-top: 10px;
    margin-bottom: 30px;
}


/* 教育项目-手风琴特效 */
.edu_project .accordion {
    width: 100%;
    height: 360px;
    overflow: hidden;
    background-color: #858488;
    border-bottom: 4px solid #8c1c21;
}

.edu_project .accordion ul {
    width: 100%;
    height: 100%;
    /* transition: all .3s; */
    padding: 0;
}

.edu_project .accordion ul li {
    /* 相对定位 */
    position: relative;
    float: left;
    list-style-type: none;
    width: 14.28%;
    /* margin-right: 2%; */
    /* height: 320px; */
    height: 100%;
    /* 过渡为0.5秒 */
    transition: all .3s;
    /* background-image: url(../img/advantage_small.png); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.edu_project .accordion ul:hover li {
    width: 13.3%;
}

.edu_project .accordion ul li:hover {
    width: 20%;
    /* background-image: url(../img/advantage_large.png); */
}

.edu_project .accordion ul li .div_box {
    /* 绝对定位 */
    position: absolute;
    left: 0%;
    top: 0px;
    width: 100%;
    color: #fff;
    transition: all .3s;
    overflow: hidden;
}

.edu_project .accordion ul li .div_2 {
    display: none;
}

.edu_project .accordion ul li:hover .div_2 {
    display: block;
}

.edu_project .accordion ul li:hover .div_1 {
    display: none;
}





.teacher_group .ant-carousel .slick-dots-bottom {
    bottom: -20px !important;
}

.index_bg {
    background-image: url(../img/index_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.index_bottom_red {
    background-image: url(../img/index_bg_red.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #ffffff;
    padding: 50px 0 60px;
}

.index_bottom_red_title_each {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
}

.index_bottom_red_each {
    width: 25%;
    text-align: center;
}

.index_bottom_black {
    background-image: url(../img/index_bg_black.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #ffffff;
    padding: 100px 0 100px;
    font-size: 16px;
}

.index_bottom_black a {
    color: #ffffff;
}

.index_bottom_black a:hover {
    color: #ffffff;
}

.index_bottom_icon_box {
    text-align: center;
}

.index_bottom_icon_box div {
    margin-top: 3px;
}

.microeducation_course_item_tag2 {
    display: none;
}

.microeducation_course_item:hover .microeducation_course_item_tag1 {
    display: none;
}

.microeducation_course_item:hover .microeducation_course_item_tag2 {
    display: block;
}

.vertical-text {
    writing-mode: vertical-rl;
    transform: rotate(360deg);
}

.ant-carousel button {
    background-color: #6f6f6f !important;
}

.ant-carousel .slick-active button {
    background-color: #8c1c21 !important;
}

/* 针对顶部banner的特殊设置 */
.top_banner .ant-carousel .slick-dots li {
    width: 24px;
    height: 24px;
    background-color: #6f6f6f;
    background-color: transparent !important;
    background-image: url(../img/icon-banner_2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.top_banner .ant-carousel li.slick-active {
    width: 24px;
    height: 24px;
    background-color: #8c1c21;
    background-color: transparent !important;
    background-image: url(../img/icon-banner_1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}


.top_banner .ant-carousel .slick-dots li button {
    width: 24px !important;
    height: 24px !important;
    display: block !important;
    opacity: 0 !important;
}