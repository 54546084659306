.btn_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main_width {
    width: 99%;
    max-width: 1300px;
    margin: 0 auto;
}

.nav_menu {
    color: #343434;
    margin-left: 20px;
    font-size: 16px;
}

.nav_menu:hover {
    color: #202020;
}

.nav_menu_focus {
    color: #8d1c22;
    font-weight: bold;
}

.nav_menu_focus:hover {
    color: #8d1c22 !important;
}

.ant-btn-primary-login {
    color: #fff;
    border-color: #bf2931 !important;
    background: #bf2931 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
}

.index_title_1 {
    color: #8c1c21;
    font-size: 70px;
    font-weight: bold;
}

.index_title_2 {
    margin-top: 50px;
    margin-bottom: 20px;
    color: #8c1c21;
    font-size: 25px;
}

.index_title_3 {
    font-size: 15px;
    line-height: 30px;
}

.index_title_4 {
    color: #8c1c21;
    border: 1px solid #8c1c21;
    font-size: 17px;
    width: 160px;
    text-align: center;
    padding: 3px 0;
    margin-top: 50px;
    display: inline-block;
    font-weight: bold;
}

.index_title_4:hover {
    color: #8c1c21;
}

.new_list_detail_title {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
}

.new_list_content p {
    /* text-indent: 30px; */
}

.new_list_content img {
    width: 80%;
    margin-left: 10%;
}

.img_ins {
    text-align: center;
    margin-top: 3px;
}

.vi_design a {
    color: #0085c7;
}

.school_calender img {
    width: 80% !important;
    margin-left: 10%;
}